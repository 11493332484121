import "./contentWarning.scss";

export default function Contact() {

    return (
        <section>
            <h2 className="contentWarning__Header">Content Warning</h2>
            <p className="contentWarning__Content">Content note for the Poet Empress (may contain spoilers):</p>
            <ul className="contentWarning__triggersList">
                <li className="contact__triggersListItem">Sexual violence</li>
                <li className="contact__triggersListItem">Abuse of minors</li>
                <li className="contact__triggersListItem">Intimate partner violence</li>
                <li className="contact__triggersListItem">Thoughts of ending one's own life</li>
                <li className="contact__triggersListItem">Alcoholism</li>
                <li className="contact__triggersListItem">Death</li>
            </ul>
        </section>
    )
}